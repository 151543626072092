html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  overscroll-behavior-y: none;
}
.page-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.full-page-bg {
  background-color: #eef2f1 !important;
}

.theme-dark .full-page-bg {
  background-color: #1b1d21 !important;
}

.article {
  color: #000 !important;
}
.article h1,
.article h2,
.article h3,
.article h4 {
  text-align: initial;
}

.theme-dark .article {
  color: #fff !important;
}

.cardd {
  width: fit-content;
  height: auto;
  padding: 20px;
  border: 4px solid #4af591;
  border-radius: 15px;
  margin: 20px;
  background-color: #87f7b6;
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  box-sizing: border-box;
}
.navbar-item {
  position: relative;
}
.navbar-item div {
  font-size: 13px;
}
.active .nav-icon {
  position: absolute;
  top: -20px;
  right: calc(50% - 26px);
  color: white;
  background-color: #4a89dc;
  height: 50px !important;
  width: 50px !important;
  padding: 10px;
  border-radius: 50%;
  animation: fadeInUp 200ms both;
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    padding: 15px;
    transform: translate3d(0, 30%, 0);
  }

  to {
    opacity: 1;
    padding: 10px;
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 1;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
.nav-icon {
  animation: fadeInDown 200ms both;
}
.active .navbar-name {
  margin-top: 19px;
}
.app-item {
  font-size: 0.9rem;
  margin-top: 0px;
  list-style: none;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.app-img {
  border-radius: 21%;
  border: 0.5px solid rgba(0, 0, 0, 0.1);
}
.app-name {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  font-weight: 400;
  color: #0b395f;
  margin-bottom: 0;
}
.apps-list-container {
  display: grid;
  grid-template-columns: 49% 49%;
  column-gap: 2%;
}
@media (max-width: 900px) {
  .apps-list-container {
    grid-template-columns: 100%;
  }
}
.main-slider .slick-slide:not(.slick-center) {
  transform: scale(0.9);
}
.main-slider .slick-active,
.main-slider .slick-slide {
  transition: transform 500ms;
}
.theme-dark .react-multi-carousel-dot--active button {
  background: #fff;
}
.theme-dark .searchbox .bg-highlight {
  background-color: #254269 !important;
}
.react-multi-carousel-dot button {
  border-width: 1px !important;
}
.shrink-text {
  -webkit-line-clamp: 6;
}
.app-detail-name,
.shrink-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.app-detail-name {
  -webkit-line-clamp: 1;
  padding-right: 5rem;
}
.app-item:active {
  filter: brightness(0.7);
}
a:active {
  opacity: 0.7;
}
.btn:active {
  filter: brightness(0.7);
}
.firebaseui-idp-google > .firebaseui-idp-text {
  color: #757575 !important;
}
.firebaseui-idp-button,
.firebaseui-tenant-button {
  max-width: 9999px !important;
  min-height: 46px !important;
}
.firebaseui-container {
  max-width: 9999px !important;
}
.firebaseui-card-content {
  padding: 0 !important;
}
.mdl-button {
  border-radius: 12px !important;
}
.text-break {
  white-space: pre-wrap;
}
.strike-through {
  position: relative;
}
.strike-through:before {
  position: absolute;
  content: "";
  left: -5px;
  top: 38%;
  right: -5px;
  border-top: 2.5px solid #da4453;
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
}
.ptr--ptr,
.home_ptr {
  box-shadow: inset 0 0px 0px rgba(0, 0, 0, 0.12) !important;
  pointer-events: none;
  font-size: 1em !important;
  font-weight: 100 !important;
  top: 0;
  height: 0;
  transition: height 0.3s, min-height 0.3s;
  text-align: center;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  align-content: stretch;
}
.ptr--text,
.ptr--icon {
  color: #727272 !important;
}
.home_text,
.home_icon {
  color: #fff !important;
}
.img-profile {
  object-fit: contain;
  border-radius: 50%;
  background-color: rgba(179, 179, 179, 0.15);
}
.theme-dark .img-profile {
  background-color: rgb(255 255 255 / 10%);
}
.profile-apps-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 70px);
  place-content: space-between;
  gap: 13px;
  padding: 15px;
}
.avatar-img {
  height: 40px;
  width: 40px;
}

.waves {
  position: relative;
  width: 100%;
  height: 198px;
  margin-bottom: calc(-5rem + env(safe-area-inset-top));
}
@media (min-width: 1200px) {
  .waves {
    height: 250px;
    margin-bottom: calc(-6.5rem + (1.5 * env(safe-area-inset-top)));
    clip-path: inset(-10% 0% 46% 0% round 20px);
  }
}
.parallax > use {
  animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
}
.parallax > use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}
.parallax > use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}
.parallax > use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}
.parallax > use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}
@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }
  100% {
    transform: translate3d(85px, 0, 0);
  }
}
.waves-position {
  transform: rotate(0.5turn);
  margin-bottom: -1.5rem;
}
.fixed-to-top {
  position: fixed;
  width: 100%;
}
.side-nav-item:hover {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}
.theme-dark .side-nav-item:hover {
  background: rgba(255, 255, 255, 0.03);
  border-radius: 10px;
}

.side-nav-glider {
  height: 46px;
  width: calc(100% - 2rem);
  position: absolute;
  transition: 0.25s ease-out;
  border-radius: 10px;
  background: #4a89dc;
  z-index: -1;
  display: none;
}
.active.apps-tab ~ .side-nav-glider {
  top: 82px;
  display: block;
}
.active.search-tab ~ .side-nav-glider {
  top: 145px;
  display: block;
}
.active.home-tab ~ .side-nav-glider {
  top: 207px;
  display: block;
}
.active.submit-tab ~ .side-nav-glider {
  top: 269px;
  display: block;
}
.active.settings-tab ~ .side-nav-glider {
  top: 332px;
  display: block;
}
.side-nav-icon {
  height: 1.5rem;
  border-radius: 50%;
  width: auto;
  margin: 0.7rem 1rem;
  transition: all 0.3s;
  fill: #4a89dc;
  transform: rotateY(0deg);
}
.active .side-nav-icon {
  fill: white;
  transform: rotateY(180deg);
  transition: all 0.3s;
}
.side-nav-text {
  font-size: 1rem;
  font-weight: 500;
  color: #4a89dc;
  text-transform: uppercase;
}
.active .side-nav-text {
  color: #fff;
}
.theme-dark .side-nav-text {
  color: #fff;
}
.theme-dark .side-nav-icon {
  fill: #fff;
}
.theme-dark .side-nav-glider {
  background: #244269;
}
.z-10 {
  z-index: 10;
}
.z-999 {
  z-index: 999;
}
.bg-inherit {
  background-color: inherit !important;
}
.sidebar-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.sidebar-container {
  position: relative;
  width: 260px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  color: black;
  background: rgb(255, 255, 255);
  border-radius: 20px 0px 0px 20px;
  height: calc(
    95% - constant(safe-area-inset-bottom) - constant(safe-area-inset-top)
  );
  height: calc(95% - env(safe-area-inset-bottom) - env(safe-area-inset-top));
  height: 90dvh;
}
.theme-dark .sidebar-container {
  color: white;
  background: rgb(21 21 21);
}
.sidebar {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;
  overflow: auto;
  position: fixed;
  right: 0;
  width: 20%;
}
.side-profile-card {
  text-align: center;
  margin: 4rem 1rem;
  padding-top: 4rem;
}
.side-avatar {
  position: absolute;
  border: 10px solid #eef2f1;
  top: -3rem;
  left: calc(50% - 50px);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  margin: auto;
  overflow: hidden;
}
.object-cover {
  object-fit: cover;
}
.theme-dark .side-avatar {
  border: 10px solid #1b1d21;
}
.slider-app-item {
  width: 40%;
  position: absolute;
  top: 240px;
  left: 70px;
}
.slider-app-name {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 13px;
  line-height: 1rem;
  font-weight: 400;
  color: #0b395f;
  margin: 0;
  margin-top: 0.4rem;
}
.slider-app-icon {
  width: 80px;
  height: 80px;
  margin: auto;
}
.slider-app-btn {
  background: #4a89dc;
  color: white;
  font-size: 0.8rem;
  line-height: normal;
  border-radius: 20px;
  padding: 2px 6px;
}
.slider-old-price {
  text-decoration: line-through;
  color: #da4453 !important;
  font-size: 0.8rem;
  margin-right: 5px;
}
.theme-dark .slider-old-price {
  color: rgb(232, 61, 61) !important;
}
.slider-app-description {
  font-size: 0.8rem;
  font-weight: 500;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  margin: 2rem 1rem 4.5rem;
  text-align: center;
}
.side-navbar {
  width: 18%;
}
.curser-pointer {
  cursor: pointer;
}
.burger-menu {
  position: relative;
  height: 24px;
  width: 24px;
  cursor: pointer;
  overflow: visible;
}

.menu__icon {
  position: absolute;
  width: 24px;
  height: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.menu__line {
  position: absolute;
  left: 0;
  background-color: #fff;
  height: 4px;
  width: 100%;
  border-radius: 30px;
  pointer-events: none;
}
.menu__line--1 {
  top: 0;
}
.menu__line--2 {
  top: 0;
  bottom: 0;
  margin: auto;
}
.menu__line--3 {
  bottom: 0;
}
.menu--back2 .menu__line--1,
.menu--back2 .menu__line--3 {
  transition: transform 0.3s ease;
}
.menu--back2.open .menu__line--1 {
  transform: translate3d(-6.5px, 3.7px, 0) rotate(138deg) scaleX(0.7);
  transition: transform 0.3s ease;
}
.menu--back2.open .menu__line--3 {
  transform: translate3d(-6.5px, -3.7px, 0) rotate(-138deg) scaleX(0.7);
  transition: transform 0.3s ease;
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.backdrop-center {
  align-items: center;
}
.action-sheet {
  width: clamp(50%, 450px, 100%);
  color: black;
  background: rgb(255, 255, 255);
  border-radius: 20px;
  margin: 0 auto 20px auto;
}
.theme-dark .action-sheet {
  color: white;
  background: rgb(21 21 21);
}
.opacity-0 {
  opacity: 0;
}
.transparency {
  background-color: rgba(255, 255, 255, 0.65) !important;
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px) !important;
}
.theme-dark .transparency {
  background-color: rgb(21 21 21 / 65%) !important;
  backdrop-filter: blur(12px) !important;
  -webkit-backdrop-filter: blur(12px) !important;
}
.word-break {
  white-space: break-spaces;

}
.full-submitter-description {
  overflow: auto;
  min-height: 5rem;
  max-height: calc(100vh - 400px);
  height: fit-content;
  font-size: 1.1rem;
  white-space: break-spaces;
}
.full-submitter-card {
  width: min(95vw, 800px);
  max-height: 70vh;
}
.submitter-card {
  z-index: 1;
}
.ios-no-scroll,
.ios-no-scroll * {
  touch-action: pan-x;
}
.drawer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.social-icon {
  width: 3rem;
  height: 3rem;
  padding: 10px;
}
span {
  color: #1b1d21;
}

.top-bar-shadow {
  opacity: 1;
  transition: opacity 300ms ease;
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, #1b1d21 100%);
  position: fixed;
  inset: 0;
  width: 100%;
  height: calc(70px + (constant(safe-area-inset-top)) * 0.75);
  height: calc(70px + (env(safe-area-inset-top)) * 0.75);
  z-index: 9;
}

.theme-light .app-bg-gradient-fade {
  background: linear-gradient(
    to bottom,
    rgba(240, 240, 240, 0) 0%,
    rgba(240, 240, 240, 0) 40%,
    rgba(247, 247, 247, 0.95) 100%
  );
}
.theme-dark .app-bg-gradient-fade {
  background: linear-gradient(
    to bottom,
    rgba(27, 29, 33, 0) 0%,
    rgba(27, 29, 33, 0) 40%,
    #1b1d21 100%
  );
}

.route-spinner {
  animation: reveal 500ms;
}
@keyframes reveal {
  0% {
    opacity: 0;
  }
  90% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.signup-photo {
  fill: black;
  border: 3px solid #fff;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  padding: 4px;
  background-color: #ecedf1;
  position: absolute;
  bottom: 5px;
  right: -5px;
}

.theme-dark .signup-photo {
  fill: white;
  border: 3px solid #0f1117;
  background-color: #1c1d21;
}

.submitter-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  opacity: 0.8;
  line-height: 14px;
}

button:disabled {
  opacity: 0.7;
  filter: grayscale(0.5);
  pointer-events: none;
}

.list-item:active {
  background-color: #72727223;
  border-radius: 10px;
}

/* Define the initial state of the bell icon */
.bell-icon {
  transform-origin: center top;
  transform: rotate(0deg);
  animation: swing 1s ease-in-out infinite;
}

/* Define the animation */
@keyframes swing {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-10deg);
  }
  30% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(2deg);
  }
  60% {
    transform: rotate(-2deg);
  }
  70% {
    transform: rotate(0deg);
  }
  80% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.feed-card {
  display: flex;
  flex-direction: row !important;
  justify-content: space-between;
  align-items: center;
}

.plus-sign::after {
  content: "+";
  position: absolute;
  top: -1px;
  font-size: 13px;
  left: 3px;
}

.react-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  margin: 0 !important;
  background-color: #e2e5ea ;
  border-radius: 16px;
  font-size: 13px;
}

.react-button img {
  margin-right: 5px !important;
}

.theme-dark .react-button {
  background-color: #434a54 ;
  color: #fff ;
}

/* Add to your CSS */
.emoji-avatar-group {
  display: flex;
  flex-direction: row;
}

.emoji-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #e2e5ea;
  position: relative;
}

.theme-dark .emoji-avatar {
  background-color: #434a54 ;
  color: #fff ;
}